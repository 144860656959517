import React, { useState, useEffect, Fragment, useRef, ChangeEvent, useMemo, InputHTMLAttributes, forwardRef, useCallback } from 'react';
import { Button, Row, Col, Card, CardBody, Label, Badge, Input, InputGroup, Nav, Modal, ModalHeader, ModalBody, Form, FormGroup } from 'reactstrap';
import Select, { SingleValue } from 'react-select';
import classnames from "classnames";
import { ChevronLeft, ChevronRight, Download, Trash2, X, } from 'react-feather';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import webservice from '../../../Service/webservice';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import moment from 'moment';
import 'flatpickr/dist/themes/material_blue.css';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver'
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../ReaduxToolkit/Hooks';
import { getData, Get_permission } from './store';
import websocket_webservice from '../../../Service/websocket_webservice'
interface DeleteRowData {
    Patientname: string;
    id: string;
    PatientDOB: string;
    Memberid: string;
    CompanyName: string;

    uploaded_date: string;
    RefId: string;
    Payor_ID: string;
    Provider_NPI: string;
    Payer_ID: string;
    Provider_Name: string;
    Provider_ID: string;
    Payer_Name: string;
    Data_Fetched: string;
    xml_conversion: string;
}


interface IDefaultValues {
    DoB: string;
    firstName: string;
    MiddleName?: string;
    lastName: string;
    Memberid: string;
    PayorID: string;
    ProviderNPI: string;
    ClientName: string;
    Address: string;
    Insurancename: string;
    Subscriber: string;
}

const defaultValues: IDefaultValues = {
    DoB: '',
    firstName: '',
    MiddleName: '',
    lastName: '',
    Memberid: '',
    PayorID: '',
    ProviderNPI: '',
    ClientName: '',
    Insurancename: '',
    Address: '',
    Subscriber: '',
};

interface IClientOption {
    label: string;
    value: string;
}

const CustomHeader = ({ handleFilter, value, handlePerPage, rowsPerPage }: any) => {
    return (
        <div className='client-master-list-table-header w-100 py-2'>
            <Row>
                <Col lg='6' className='d-flex align-items-center px-0 px-lg-1'>
                    <div className='d-flex align-items-center me-4 '>
                        <label htmlFor='rows-per-page' className='me-2'>Rows per Page:</label>
                        <Input
                            type='select'
                            id='rows-per-page'
                            value={rowsPerPage}
                            onChange={handlePerPage}
                            className='form-control ms-50 larger-select '
                        >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                        </Input>
                    </div>
                </Col>
                <Col
                    lg='6'
                    className='d-flex align-items-center justify-content-lg-end mt-lg-0 mt-1 px-0 px-lg-3'
                >
                    <InputGroup className="input-group-search mb-2 zoom-on-hover">
                        <Input
                            type="text"
                            placeholder="🔍 Search...."
                            value={value}
                            onChange={(e) => handleFilter(e.target.value)}
                            className="input-search"
                        />
                    </InputGroup>
                </Col>
            </Row>
        </div>
    );
};

const Bulkupload: React.FC = () => {
    const { control, handleSubmit, formState: { errors }, reset } = useForm<IDefaultValues>({
        defaultValues,
    });
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedFileName, setSelectedFileName] = useState<string>("");
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedClientId, setSelectedClientId] = useState<string>('');
    const [selectedClient, setSelectedClient] = useState<string>('');
    const [selectedtemplate, setselectedtemplate] = useState<string>('');
    const [selectedtemplateId, setselectedtemplateId] = useState<string>('');
    const Username = localStorage.UserName;
    const [usertype] = useState<string>(localStorage.UserType);


    const [id, setId] = useState<string>('');

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const toggle = () => setIsOpen(!isOpen);

    const [eventId, setEventId] = useState<DeleteRowData[]>([]);
    const [eventId1, setEventId1] = useState(0);
    const selectedRowsPerPage: { [key: number]: DeleteRowData[] } = useMemo(() => ({}), []);
    // const menuActions = ['add', 'delete'];
    const action = { fromUser: true };
    const dispatch = useAppDispatch()
    const store = useSelector((state: any) => state.BulkUpload)
    const [value, setValue] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [sortColumn, setSortColumn] = useState<any>('uploaded_date'); // Example initial sort column
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [loading, setLoading] = useState(false);
    const [clientOptions, setClientOptions] = useState<IClientOption[]>([]);
    const [vendorbasedclientOptions, setvendorbasedclientOptions] = useState<IClientOption[]>([]);
    const [templateOptions, settemplateOptions] = useState<IClientOption[]>([]);
    const [isCardModalOpen, setIsCardModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [Addresseoption, setAddress] = useState<IClientOption[]>([]);
    const [SelectedAddressname, setSelectedAddress] = useState<string>('');
    const [SelectedAddressId, setSelectedAddressId] = useState<string>('');
    useEffect(() => {
        if (usertype === 'Vendor') {
            setId(localStorage.getItem('VendorId') || '');
        } else
            if (usertype === 'Client') {
                setId(localStorage.getItem('ClientId') || '');
            }
            else if (usertype === 'MOS') {
                setId(localStorage.getItem('id') || '');
            }
    }, [usertype]);

    const VendorOptions: any = [
        { value: 'AF', label: 'vendor1' },
        { value: 'AX', label: 'vendor2' },
    ]

    const [menuActions, setMenuActions] = useState<string>('');

    useEffect(() => {
        if (localStorage.id !== null) {
            dispatch(Get_permission(localStorage.id)).then((response) => {
                const res1 = response.payload;
                const Action = res1[0].Permission.filter(
                    (item: any) => item.MenuKey === "BULKUPLOAD"
                );
                setMenuActions(Action[0].MenuActions);
            });
        }
    }, [localStorage.id])


    // useEffect(() => {
    //     if (usertype && id) {
    //         dispatch(
    //             getData({
    //                 id,
    //                 usertype,
    //                 q: value,
    //                 perPage: rowsPerPage,
    //                 page: currentPage,
    //                 sort,
    //                 sortColumn
    //             })
    //         );
    //     }
    // }, [dispatch, store.data.length, usertype, id]);
    useEffect(() => {
        const fetchData = () => {
            if (usertype && id !== undefined) {
                dispatch(
                    getData({
                        id,
                        usertype,
                        q: value,
                        perPage: rowsPerPage,
                        page: currentPage,
                        sort,
                        sortColumn
                    })
                );
            }
        };

        // Fetch initial data
        fetchData();

        // Establish WebSocket connection
        const socket = new WebSocket(`${websocket_webservice}ws/BulkuploadPatient/`);

        // socket.onopen = () => console.log('WebSocket connected');
        // socket.onclose = () => console.log('WebSocket closed');
        // socket.onerror = (error) => console.error('WebSocket error:', error);

        socket.onmessage = () => {
            // const change = JSON.parse(event.data);
            // console.log(change);

            // Re-fetch the data to update the state
            fetchData();
        };

        return () => {
            if (socket.readyState === 1) { // <-- This is important
                socket.close();
            }
        }
    }, [dispatch, store.data.length, id, usertype, sort, value, sortColumn, currentPage, rowsPerPage,]);

    const handleReloadTable = () => {
        dispatch(
            getData({
                id,
                usertype,
                q: value,
                perPage: rowsPerPage,
                page: currentPage,
                sort,
                sortColumn
            })
        );
    };


    const handleFilter = (val: any) => {
        setValue(val)
    setCurrentPage(1)
        dispatch(
            getData({
                id,
                usertype,
                q: val,
                perPage: rowsPerPage,
                page: currentPage,
                sort,
                sortColumn,
            })
        )
    }

    const handlePerPage = (e: any) => {
        setCurrentPage(1)
        dispatch(
            getData({
                id,
                usertype,
                q: value,
                perPage: parseInt(e.target.value),
                page: currentPage,
                sort,
                sortColumn,
            })
        )
        setRowsPerPage(parseInt(e.target.value))
    }

    const handlePagination = (page: any) => {
        dispatch(
            getData({
                id,
                usertype,
                q: value,
                perPage: rowsPerPage,
                page: page.selected + 1,
                sort,
                sortColumn,
            })
        )
        setCurrentPage(page.selected + 1)
    }


    const dataToRender = () => {
        const filters: any = {
            q: value

        }
        const isFiltered = Object.keys(filters).some(function (k) {
            return filters[k].length > 0
        })

        if (store.data.length > 0) {
            return store.data
        } else if (store.data.length === 0 && isFiltered) {
            return []
        } else {
            return store.allData.slice(0, rowsPerPage)
        }
    }

    const handleSort = (column: any, sortDirection: any) => {
        setSort(sortDirection)
        setSortColumn(column.sortField)
        dispatch(
            getData({
                id,
                usertype,
                q: value,
                perPage: rowsPerPage,
                page: currentPage,
                sort: sortDirection,
                sortColumn: column.sortField
            })
        )
    }

    const CustomPagination = () => {
        const count = Number(Math.ceil(store.total / rowsPerPage))

        return (
            <ReactPaginate
                previousLabel={<ChevronLeft size={16} />}
                nextLabel={<ChevronRight size={16} />}
                breakLabel="..."
                pageCount={count || 1}
                onPageChange={handlePagination}
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                containerClassName="pagination-container"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="active"
            />
        )
    }

    useEffect(() => {
        if (usertype !== 'MOS' && usertype !== "Vendor") {
            setSelectedClient(localStorage.getItem('ClientName') || '');
            setSelectedClientId(localStorage.getItem('ClientId') || '');
        }
    }, [usertype]);

    const get_companyname = async () => {
        const response = await fetch(webservice + 'api/BulkUpload/companyname', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
    };

    const get_companynamevendorbased = async () => {
        const EncParams = AesEncrypt(JSON.stringify({ id: localStorage.VendorId }));

        const response = await fetch(webservice + 'api/BulkUpload/vendorbasedclientOptions', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
    };
    // useEffect(() => {
    //     const vendorbasedfetchRoleId = async () => {
    //         const response = await get_companynamevendorbased();

    //         const res = response.map((data: any) => ({
    //             label: data.CompanyName,
    //             value: data.id,
    //         }));
    //         setvendorbasedclientOptions(res);
    //     };

    //     vendorbasedfetchRoleId();
    // }, []);
    useEffect(() => {
        const vendorbasedfetchRoleId = async () => {
            const response = await get_companynamevendorbased();
            const res = response.map((data: any) => ({
                label: data.CompanyName,
                value: data.id,
            }));
            setvendorbasedclientOptions(res);
        };

        // Fetch the initial data
        vendorbasedfetchRoleId();

        // WebSocket setup
        const socket = new WebSocket(`${websocket_webservice}ws/ClientDropdown/`);

        // socket.onopen = () => console.log('WebSocket connected');
        // socket.onclose = () => console.log('WebSocket closed');
        // socket.onerror = (error) => console.error('WebSocket error:', error);

        socket.onmessage = () => {
            // const change = JSON.parse(event.data);
            // console.log('WebSocket message received:', change);

            // Optionally, re-fetch the data to update the state when a WebSocket message is received
            vendorbasedfetchRoleId();
        };

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            if (socket.readyState === 1) { // <-- This is important
                socket.close();
            }
        }
    }, []);


    // useEffect(() => {
    //     const fetchRoleId = async () => {
    //         const response = await get_companyname();
    //         const res = response.map((data: any) => ({
    //             label: data.CompanyName,
    //             value: data.id,
    //         }));
    //         setClientOptions(res);
    //     };

    //     fetchRoleId();
    // }, []);

    useEffect(() => {
        const fetchRoleId = async () => {
            const response = await get_companyname();
            const res = response.map((data: any) => ({
                label: data.CompanyName,
                value: data.id,
            }));
            setClientOptions(res);
        };

        // Fetch the initial data
        fetchRoleId();

        // WebSocket setup
        const socket = new WebSocket(`${websocket_webservice}ws/ClientDropdown/`);

        // socket.onopen = () => console.log('WebSocket connected');
        // socket.onclose = () => console.log('WebSocket closed');
        // socket.onerror = (error) => console.error('WebSocket error:', error);

        socket.onmessage = () => {
            // const change = JSON.parse(event.data);
            // console.log('WebSocket message received:', change);

            // Optionally, re-fetch the data to update the state when a WebSocket message is received
            fetchRoleId();
        };

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            if (socket.readyState === 1) { // <-- This is important
                socket.close();
            }
        }
    }, []);


    const handleClearFiles = () => {
        setSelectedFile(null);
        setSelectedFileName('');
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Clear the input value
        }
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;
        if (file) {
            const fileName = file.name;
            if (!fileName.toLowerCase().endsWith('.xlsx') && !fileName.toLowerCase().endsWith('.xls')) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Insurance Verification Platform',
                    text: 'Only Excel files are allowed!',
                });
                return;
            }
            setSelectedFile(file);
            setSelectedFileName(fileName);
        }
    };

    const handleSelectClientChange = (selectedOption: SingleValue<IClientOption>) => {
        if (selectedOption) {
            setSelectedClient(selectedOption.label);
            setSelectedClientId(selectedOption.value);
    
            // Clear address and template when client is changed
            setSelectedAddress(''); // Reset address
            setSelectedAddressId('');
            setAddress([]); // Assuming setAddress is setting address options
            setselectedtemplate(''); // Reset template
            setselectedtemplateId('');
        } else {
            // Reset all fields if no client is selected
            setSelectedClient('');
            setSelectedClientId('');
            setSelectedAddress('');
            setSelectedAddressId('');
            setselectedtemplate('');
            setselectedtemplateId('');
            setAddress([]); // Reset the address options
        }
    };


    const handleSelecttemplateChange = (selectedOption: SingleValue<IClientOption>) => {
        if (selectedOption) {
            setselectedtemplate(selectedOption.label);
            setselectedtemplateId(selectedOption.value);
        } else {

            setselectedtemplate('');
            setselectedtemplateId('');
        }
    };

    const handleAddressChange = (selectedOption: SingleValue<IClientOption>) => {
        if (selectedOption) {
            setSelectedAddress(selectedOption.label);
            setSelectedAddressId(selectedOption.value);
        } else {
            setSelectedAddress('');
            setSelectedAddressId('');
        }
    };

    const resetForm = () => {
        setSelectedFile(null);
        setSelectedFileName('');
        setSelectedClient('');
        setSelectedClientId('');
        setselectedtemplate('');
        setselectedtemplateId('');
        setSelectedAddress('');
        setSelectedAddressId('');
        setAddress([]);
    };    
    const handleSave = async () => {

        if (!selectedFile || !selectedClient) {
            Swal.fire({
                icon: 'warning',
                title: 'Insurance Verification Platform',
                text: 'Please Select Client!',
            });
            return;
        }
        if (!selectedFile || !selectedtemplate) {
            Swal.fire({
                icon: 'warning',
                title: 'Insurance Verification Platform',
                text: 'Please Select Template!',
            });
            return;
        }
        
        setLoading(true);
        const vendorid = (localStorage.UserType === 'Vendor') ? localStorage.VendorId : "";

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('ClientName', selectedClient);
        formData.append('ClientId', selectedClientId);
        formData.append('template', selectedtemplate);
        formData.append('templateId', selectedtemplateId);
        formData.append('Address', SelectedAddressname);
        formData.append('vendor_id', vendorid);
        formData.append('Uploaded_userid', localStorage.id);

       
        try {
            const response = await axios.post(webservice + 'api/bulkupload', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            const auditLogData = [
                { field: "File Name", value: selectedFileName },
                { field: "Client Name", value: selectedClient },
                { field: "Template Name", value: selectedtemplate },
                { field: "Address", value: SelectedAddressname }
            ];
            const Username = localStorage.getItem('UserName') || '';
            const auditData = { auditLogData, Username };

            if (response.data === 'Success') {
                await axios.post(webservice + 'api/BulkUpload/AddauditLogdata', auditData, {
                    headers: { 'Content-type': 'application/json; charset=UTF-8' }
                });

                // Reset form and state
                resetForm();
                setLoading(false);

                Swal.fire({
                    icon: 'success',
                    title: 'File saved successfully!',
                }).then(async () => {
                    handleReloadTable()
                    toggle();
                    resetForm();
                    setSelectedClient('');

                    // First API call to check the condition
                    const api = await axios.get(webservice + 'api/elligibilityapi');
                    if (api.data === 'done') {
                        const xmlcreation = await axios.get(webservice + 'api/elligibilityapi/Mapping');
                        // console.log('XML creation triggered:', xmlcreation.data);
                    } else {
                        // console.log('API response is not "done". No XML creation triggered.');
                    }
                });
            } else if (response.data === "Uploaded file does not match the sample file's columns.") {
                Swal.fire({
                    icon: 'warning',
                    title: "Uploaded file does not match the sample file's columns.Download sample Template",
                });
                handleReloadTable()
                toggle();
                resetForm();
                setSelectedClient('');
                setLoading(false);
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Not Uploaded. Please try again!',
                }).then(() => {
                    handleReloadTable()
                    toggle();
                    resetForm();
                    setSelectedClient('');
                    setLoading(false);
                });
            }
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error occurred!',
                text: 'Please try again later.',
            });
        }
    };

    const downloadTemplate = async () => {
        try {
            const response = await fetch(`${webservice}api/getfile_excel`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            // Check if the file_data key exists
            if (!responseData.file_data) {
                throw new Error('No file data in response');
            }
            // Decode the base64 data
            const byteCharacters = atob(responseData.file_data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            // Create a blob from the byte array
            const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            // Create a temporary URL for the blob and initiate download
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'BulkUpload.xlsx');
            document.body.appendChild(link);
            link.click();
            // Check if the parentNode exists before removing the link
            if (link.parentNode) {
                link.parentNode.removeChild(link);
            }
        } catch (error) {
            console.error('Error downloading file: ', error);
        }
    };

    // const template = async () => {
    //     try {

    //         const EncParams = AesEncrypt(JSON.stringify({ selectedClientId }));
    //         const response = await fetch(webservice + 'api/BulkUpload/template', {
    //             method: 'POST',
    //             body: JSON.stringify({ EncParams }),
    //             headers: {
    //                 'Content-type': 'application/json; charset=UTF-8',
    //             },
    //         });
    //         const result = await response.json();
    //         const decryptedResult = AesDecrypt(result);
    //         const res = decryptedResult.map((data: any) => ({
    //             label: data.file_name,
    //             value: data.id,
    //         }));
    //         settemplateOptions(res);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };

    // useEffect(() => {
    //     template();
    // }, [selectedClientId]);

    useEffect(() => {
        const template = async () => {
            if (!selectedClientId) {
                // If selectedClientId is an empty string or falsy, return early
                return;
            }
            try {
                const EncParams = AesEncrypt(JSON.stringify({ selectedClientId }));
                const response = await fetch(webservice + 'api/BulkUpload/template', {
                    method: 'POST',
                    body: JSON.stringify({ EncParams }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                });

                const result = await response.json();
                const decryptedResult = AesDecrypt(result);
                const res = decryptedResult.map((data: any) => ({
                    label: data.file_name,
                    value: data.id,
                }));

                settemplateOptions(res);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Fetch the initial template data
        template();

        // WebSocket setup
        const socket = new WebSocket(`${websocket_webservice}ws/Templatedropdown/`);

        // socket.onopen = () => console.log('WebSocket connected');
        // socket.onclose = () => console.log('WebSocket closed');
        // socket.onerror = (error) => console.error('WebSocket error:', error);

        socket.onmessage = () => {
            // const change = JSON.parse(event.data);
            // console.log('WebSocket message received:', change);

            // Re-fetch the data to update the state when a WebSocket message is received
            template();
        };

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            if (socket.readyState === 1) { // <-- This is important
                socket.close();
            }
        }
    }, [selectedClientId]);

    useEffect(() => {
        const get_Address = async () => {
            if (!selectedClientId) {
                // If selectedClientId is an empty string or falsy, return early
                return;
            }

            try {
                const EncParams = AesEncrypt(JSON.stringify({ selectedClientId }));
                const fetchResponse = await fetch(webservice + 'api/address', {
                    method: 'POST',
                    body: JSON.stringify({ EncParams }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                });

                const data = await fetchResponse.json();
                const data1 = AesDecrypt(data);

                const res = data1.flatMap((item: any) =>
                    item.Address.map((address: string) => ({
                        label: address,
                        value: address,
                    }))
                );

                setAddress(res);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Fetch the initial address data
        get_Address();

        // WebSocket setup
        const socket = new WebSocket(`${websocket_webservice}ws/ClientDropdown/`);

        // socket.onopen = () => console.log('WebSocket connected');
        // socket.onclose = () => console.log('WebSocket closed');
        // socket.onerror = (error) => console.error('WebSocket error:', error);

        socket.onmessage = () => {
            // const change = JSON.parse(event.data);
            // console.log('WebSocket message received:', change);

            // Optionally, re-fetch the data to update the state when a WebSocket message is received
            get_Address();
        };

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            if (socket.readyState === 1) { // <-- This is important
                socket.close();
            }
        }
    }, [selectedClientId]);


    // const get_Address = async () => {
    //     if (!selectedClientId) {
    //         // If selectedClientId is an empty string or falsy, return early
    //         return;
    //     }

    //     try {
    //         const EncParams = AesEncrypt(JSON.stringify({ selectedClientId }));
    //         const fetchResponse = await fetch(webservice + 'api/address', {
    //             method: 'POST',
    //             body: JSON.stringify({ EncParams }),
    //             headers: {
    //                 'Content-type': 'application/json; charset=UTF-8',
    //             },
    //         });

    //         const data = await fetchResponse.json();
    //         const data1 = AesDecrypt(data);

    //         const res = data1.flatMap((item: any) =>
    //             item.Address.map((address: string) => ({
    //                 label: address,
    //                 value: address,
    //             }))
    //         );

    //         setAddress(res);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };

    // useEffect(() => {
    //     get_Address();
    // }, [selectedClientId]);

    const deleteUpload = async (ids: any[]) => {
        const idsString = JSON.stringify(ids);
        const EncParams = AesEncrypt(idsString);
        await fetch(webservice + 'api/bulkupload/DeleteBulkupload', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
    };

    const DeleteAuditLogdata = async (data: any[]) => {
        data.forEach(item => {
            item.Userid = localStorage.id;
            item.ParentUserId = localStorage.ParentUserId;
        });
        const EncParams = AesEncrypt(JSON.stringify(data));
        const response = await fetch(webservice + 'api/bulkupload/deleteauditlog', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then(response => response.json());
        return response;
    };

    const DeleteUpload = async (rows: any) => {
        const ids = rows.map((row: any) => row.id);
        const Data = rows.map((row: any) => ({
            Username,
            RefId: row.RefId,
            Name: row.filename,
        }));

        const deleteRow = async () => {
            Swal.fire({
                title: "Insurance Verification Platform",
                text: "Are you sure do you want to delete?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ms-1",
                },
                buttonsStyling: false,
                allowOutsideClick: false
            })
                .then(async function (result) {
                    if (result.isConfirmed) {
                        await deleteUpload(ids);
                        await DeleteAuditLogdata(Data);
                        Swal.fire({
                            icon: "success",
                            title: "Insurance Verification Platform",
                            text: "Document has been deleted.",
                            customClass: {
                                confirmButton: "btn btn-success",
                            },
                            allowOutsideClick: false
                        });
                        handleReloadTable()
                    }
                });
        };
        deleteRow();
    };

    const [isDownloading, setIsDownloading] = useState(null);




    const getallpatientdetails_Zipfile = async (row: any) => {
        setIsDownloading(row.id);
        try {
            const EncParams = AesEncrypt(JSON.stringify({ id: row.id }));
            const response = await fetch(webservice + 'api/getallpatientdetails_Zipfile', {
                method: 'POST',
                body: JSON.stringify({ EncParams }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();
            const OutData = AesDecrypt(responseData);
            if (!OutData.file) {
                throw new Error('No file data in response');
            }


            const zipData = Uint8Array.from(atob(OutData.file), (c) => c.charCodeAt(0));
            const blob = new Blob([zipData], { type: "application/zip" });
            const filename = row.filename;
            const baseFilename = filename.substring(0, filename.lastIndexOf('.'));
            const date = new Date();
            const day = ("0" + date.getDate()).slice(-2);
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const year = date.getFullYear();
            const formattedDate = day + month + year;
            saveAs(blob, `${baseFilename}_${formattedDate}.zip`);
            setIsDownloading(null);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const handleDownloadstatus = async (row: any) => {
      // This should log 'id' correctly now
            const EncParams = AesEncrypt(JSON.stringify({ id: row.id })); // Add a non-null assertion here
            const response = await fetch(webservice + 'api/Dashboard/UpdateDownloadStatus', {
                method: 'POST',
                body: JSON.stringify({ EncParams }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            });
            const responseData = await response.json();
    };


    interface BootstrapCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
        id: string;
    }
    // Handle individual row checkbox change
    const BootstrapCheckbox = forwardRef<HTMLInputElement, BootstrapCheckboxProps>(
        ({ id, ...props }, ref) => (
            <div style={{ position: 'relative', left: '10px' }}>
                <input type='checkbox' id={id} ref={ref} {...props} />
            </div>
        )
    );


    const handleChangecheck = useCallback(
        ({ selectedRows }: any) => {
            const selectedEventIds = [];
            selectedRows.forEach((row: any) => {
                selectedEventIds.push(row.id);
            });
            setEventId(selectedRows)
            setEventId1(selectedEventIds.length)
            if (!action.fromUser)
                return;
            selectedRowsPerPage[currentPage] = selectedRows;
        },
        [currentPage, selectedRowsPerPage, action.fromUser]);
    const toggleCardModalOpen = () => {
        setIsCardModalOpen(!isCardModalOpen);
    };

    const handleAssign = async (row: any) => {
        setSelectedRow(row);
        setIsCardModalOpen(true);
    };

    const handleAssignVendor = async () => {
        setIsCardModalOpen(false);
    };

    const timezone = moment.tz.guess();
    const Columns = [
        {
            name: 'Uploaded by',
            selector: (row: any) => row.UserName !== '' ? row.UserName : 'NA',
            sortable: true,
            filter: true,
            filterType: 'text',
        },
        {
            name: 'File Name',
            selector: (row: any) => row.filename !== '' ? row.filename : 'NA',
            sortable: true,
            filter: true,
            filterType: 'text',
        },
        {
            name: 'Client Name',
            selector: (row: any) => row.CompanyName !== '' ? row.CompanyName : 'NA',
            sortable: true,
            filter: true,
            filterType: 'select', // Use select filter type for dropdown input
            // Pass filter options to the filterValue method
            filterValue: (row: any) => clientOptions.find(option => option.value === row.CompanyName)?.label || 'NA',
        },
        {
            name: 'Template Name',
            selector: (row: any) => row.template !== '' ? row.template : 'NA',
            sortable: true,
            filter: true,
            filterType: 'select', // Use select filter type for dropdown input
            // Pass filter options to the filterValue method
            filterValue: (row: any) => clientOptions.find(option => option.value === row.template)?.label || 'NA',
        },
        {
            name: 'Uploaded Date',
            // selector: (row: any) => row.uploaded_date,
            selector: (row: any) => moment.utc(row.uploaded_date).tz(timezone).format('MM/DD/YYYY hh:mm:ss A'),

            sortable: true,
            filter: true,
            filterType: 'custom',
            customFilterDateParse: (dateStr: string) => new Date(dateStr).toISOString(),
        },
        {
            name: 'Patient List',
            cell: (row: any) => (
                <Badge
                    style={{ cursor: 'pointer', }}
                    color="primary"
                    size="sm"
                    onClick={() => {
                        navigate(`/bulkupload/patient/${row.id}`);
                    }}
                >
                    <h5>View Patients</h5>
                </Badge>
            ),
        },
        {
            name: 'Actions',
            cell: (row: DeleteRowData) => {
                const Role = localStorage.Role;
                const eventId1 = 0; // Assuming you have a way to set this value appropriately

                return (
                    <>
                        {/* {eventId1 === 0 && (
                            menuActions.includes("delete") && (
                            <Col xs={2}>
                                <Trash2
                                    size={17}
                                    id='deleteIcon'
                                    color='#FF0000'
                                    onClick={() => DeleteUpload(row)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Col>
                            )
                        )} */}
                        
                        
                        {row.Data_Fetched === "Y" && (
                            
                            
                            <Col xs={2}>
                                <div
                                    onClick={() => {
                                        getallpatientdetails_Zipfile(row);  // First function
                                        handleDownloadstatus(row);       // Second function (for example)
                                    }}
                                >
                                    {isDownloading === row.id ? (
                                        <div className="spinner"></div>
                                    ) : (
                                        <Download size={20} style={{ cursor: 'pointer' }} />
                                    )}
                                </div>
                            </Col>
                        )}
                        {row.Data_Fetched === "Error" && (
                            <Col xs={2}>
                                <div style={{ color: 'red' }}>
                                    Error
                                </div>
                            </Col>
                        )}

                    </>
                );
            },
        },
        // {
        //     name: 'Assign',
        //     cell: (row: any) => (
        //         <Badge
        //             style={{ cursor: 'pointer', backgroundColor: "#355876" }}
        //             color="#355876"
        //             size="sm"
        //             onClick={() => handleAssign(row)}
        //         >
        //             <h5>Assign Patients</h5>
        //         </Badge>
        //     ),
        // },
    ]

    const customStyles = {
        rows: {
            style: {
                '&:hover': {
                    backgroundColor: '#f0f0f0',

                },
            },
        },
        headCells: {
            style: {
                backgroundColor: '#355876',  // Change background color of headers
                color: 'white',  // Change text color of headers
                fontSize: '16px',  // Adjust font size of headers
            },
        },
    };

    const GetMappedPatient = async () => {
        try {
            const response = await axios.post(`${webservice}api/getMappedPatients`);
            const data = response.data;
            // Do something with the data
            if (data.message === "Data successfully Mapped") {
                Swal.fire({
                    title: 'Insurance Verification Platform',
                    html: '<span style="color: green;">Patient Mapped successfully!</span>',
                    icon: 'success',
                    customClass: {},
                    showClass: {
                        popup: 'animate__animated animate__flipInX',
                    },
                })
            }
        } catch (error) {
            console.error('Error fetching mapped patients:', error);
        }
    };

    const startIndex = (currentPage - 1) * rowsPerPage + 1;
    const endIndex = Math.min(currentPage * rowsPerPage, store.total);
    // console.log(store);

    const CustomLoadingComponent = () => {
        return (
            <div className="">
                {store.no_Data === 'There is no Record Found' ? (
                    <h4>
                        <br/>
            <p>There are no records to display</p>
                    </h4>
                ) : (
                    <div className="custom-loader">
                        <label className="custom-label">Please wait...</label>
                        <div className="custom-loading"></div>
                    </div>
                )}
            </div>
        );
    };


    return (
        <Fragment>
            <Card>
                <CardBody>
                    <Row>
                        <div className='d-flex p-1 border'>
                            <CardBody>
                                <Row>
                                    <Col >
                                        <Button
                                            color="info"
                                            className="ml-2"
                                            onClick={downloadTemplate}

                                        >
                                            Download Template
                                        </Button></Col>
                                </Row>
                                <br />
                                <Row>
                                    {localStorage.UserType === 'MOS' && (
                                        <Col xl={3}>
                                            <Label className="form-label" style={{ fontSize: "110%" }}>
                                                Select Client <span style={{ color: "red" }}> * </span>
                                            </Label>
                                            <Select
                                                isClearable
                                                options={clientOptions}
                                                // value={clientOptions.find((e)=>e.label===selectedClient)}
                                                classNamePrefix="select"
                                                placeholder=" Client Names "
                                                //   theme={selectThemeColors}  
                                                className={classnames("react-select", {
                                                    "is-invalid": errors.ClientName && true,
                                                })}
                                                // {...field}
                                                styles={{
                                                    control: (styles) => ({
                                                        ...styles,
                                                        width: "100%",
                                                        borderColor: "black",
                                                    }),
                                                    placeholder: (styles) => ({
                                                        ...styles,
                                                        textAlign: "center",
                                                    }),
                                                }}
                                                value={selectedClient ? { value: selectedClientId, label: selectedClient } : ""}
                                                onChange={(selectedOption) => {
                                                    // field.onChange(selectedOption);
                                                    handleSelectClientChange(selectedOption as SingleValue<IClientOption>);
                                                }}
                                            />
                                            {errors.ClientName && (
                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                    {errors.ClientName.message}
                                                </span>
                                            )}
                                        </Col>
                                    )}
                                    {localStorage.UserType === 'Vendor' && (
                                        <Col xl={3}>
                                            <Label className="form-label" style={{ fontSize: "110%" }}>
                                                Select Client <span style={{ color: "red" }}> * </span>
                                            </Label>
                                            <Select
                                                isClearable
                                                options={vendorbasedclientOptions}
                                                // value={clientOptions.find((e)=>e.label===selectedClient)}
                                                classNamePrefix="select"
                                                placeholder=" Client Names "
                                                //   theme={selectThemeColors}  
                                                className={classnames("react-select", {
                                                    "is-invalid": errors.ClientName && true,
                                                })}
                                                // {...field}
                                                styles={{
                                                    control: (styles) => ({
                                                        ...styles,
                                                        width: "100%",
                                                        borderColor: "black",
                                                    }),
                                                    placeholder: (styles) => ({
                                                        ...styles,
                                                        textAlign: "center",
                                                    }),
                                                }}
                                                value={selectedClient ? { value: selectedClientId, label: selectedClient } : ""}
                                                onChange={(selectedOption) => {
                                                    // field.onChange(selectedOption);
                                                    handleSelectClientChange(selectedOption as SingleValue<IClientOption>);
                                                }}
                                            />
                                            {errors.ClientName && (
                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                    {errors.ClientName.message}
                                                </span>
                                            )}
                                        </Col>
                                    )}
                                    <Col xl={3}>
                                        <Label className="form-label" style={{ fontSize: "110%" }}>
                                            Select Address <span style={{ color: "red" }}> * </span>
                                        </Label>
                                        <Select
                                            isClearable
                                            options={Addresseoption}
                                            // value={clientOptions.find((e)=>e.label===selectedClient)}
                                            classNamePrefix="select"
                                            placeholder="Select Address "
                                            //   theme={selectThemeColors}  
                                            className={classnames("react-select", {
                                                "is-invalid": errors.Address && true,
                                            })}
                                            // {...field}
                                            styles={{
                                                control: (styles) => ({
                                                    ...styles,
                                                    width: "100%",
                                                    borderColor: "black",
                                                }),
                                                placeholder: (styles) => ({
                                                    ...styles,
                                                    textAlign: "center",
                                                }),
                                            }}
                                            value={SelectedAddressname ? { value: SelectedAddressId, label: SelectedAddressname } : ""}
                                            onChange={(selectedOption) => {
                                                // field.onChange(selectedOption);
                                                handleAddressChange(selectedOption as SingleValue<IClientOption>);
                                            }}
                                        />
                                        {errors.Address && (
                                            <span style={{ color: "red", fontSize: "12px" }}>
                                                {errors.Address.message}
                                            </span>
                                        )}
                                    </Col>
                                    <Col xl={3}>
                                        <Label className="form-label" style={{ fontSize: "110%" }}>
                                            Select Template <span style={{ color: "red" }}> * </span>
                                        </Label>
                                        <Select
                                            isClearable
                                            options={templateOptions}
                                            // value={clientOptions.find((e)=>e.label===selectedClient)}
                                            classNamePrefix="select"
                                            placeholder="Select Template "
                                            //   theme={selectThemeColors}  
                                            className={classnames("react-select", {
                                                "is-invalid": errors.Address && true,
                                            })}
                                            // {...field}
                                            styles={{
                                                control: (styles) => ({
                                                    ...styles,
                                                    width: "100%",
                                                    borderColor: "black",
                                                }),
                                                placeholder: (styles) => ({
                                                    ...styles,
                                                    textAlign: "center",
                                                }),
                                            }}
                                            value={selectedtemplate ? { value: selectedtemplateId, label: selectedtemplate } : ""}
                                            onChange={(selectedOption) => {
                                                // field.onChange(selectedOption);
                                                handleSelecttemplateChange(selectedOption as SingleValue<IClientOption>);
                                            }}
                                        />
                                        {errors.ClientName && (
                                            <span style={{ color: "red", fontSize: "12px" }}>
                                                {errors.ClientName.message}
                                            </span>
                                        )}
                                    </Col>
                                    <Col xs={3} >
                                        {!selectedFile && (
                                            <label htmlFor="fileUpload" className="btn btn-primary" style={{ marginTop: "7%" }}>
                                                <i className="fa fa-cloud-upload"></i>   Choose File
                                            </label>
                                        )}
                                        {selectedFile && (
                                            <Button onClick={handleSave} color="success" >
                                                Submit
                                            </Button>
                                        )}
                                        <input
                                            type="file"
                                            name="fileUpload"
                                            id="fileUpload"
                                            className="hidden"
                                            onChange={handleFileChange}
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                        />
                                        {selectedFileName && (
                                            <div style={{ display: "inline-block", backgroundColor: "#f0f0f0", padding: "5px", borderRadius: "5px", marginLeft: "3%", marginTop: "6%" }}>
                                                <span>FileName :</span>
                                                &nbsp;&nbsp;&nbsp;
                                                <span>{selectedFileName}</span>
                                                <X
                                                    className="pointer"
                                                    size={20}
                                                    style={{ color: "red", marginLeft: "5px" }}
                                                    onClick={handleClearFiles}
                                                />
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </CardBody>
                        </div>
                        <Col xl={12}>
                            <CardBody>
                                <div className="table-responsive">
                                    <div className="dataTables_wrapper">
                                        <DataTable
                    persistTableHead
                                            subHeader={true}
                                            responsive={true}
                                            onSort={handleSort}
                                            data={dataToRender()}
                                            columns={Columns}
                                            paginationServer
                                            customStyles={customStyles}
                                            paginationPerPage={rowsPerPage}
                                            paginationDefaultPage={currentPage}
                                            selectableRows
                                            selectableRowsHighlight
                                            selectableRowsComponent={BootstrapCheckbox as unknown as React.ReactNode}
                                            onSelectedRowsChange={handleChangecheck}
                                            paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                                            noDataComponent={<CustomLoadingComponent />}
                                            paginationComponent={CustomPagination}
                                            subHeaderComponent={
                                                <CustomHeader
                                                    value={value}
                                                    rowsPerPage={rowsPerPage}
                                                    handleFilter={handleFilter}
                                                    handlePerPage={handlePerPage}

                                                />
                                            }
                                        />
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ paddingLeft: '2%' }}>
                                                {store.total && (
                                                    <span><strong>{`${startIndex} to ${endIndex} of ${store.total} entries`}</strong></span>
                                                )}
                                            </div>
                                            <div>
                                                <CustomPagination />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <div>
                {eventId1 > 0 && menuActions.includes('delete') && (
                    <div className="customizer-links open">
                        <Nav className=" flex-column nav-pills">
                            <a className="nav-link" onClick={() => DeleteUpload(eventId)}>
                                <span>Delete</span>
                                <Trash2 className="trash-icon" size={20} />
                            </a>
                        </Nav>
                    </div>
                )}
            </div>
            {loading && (
                <div >
                    <div className="loading-overlay">
                        <div id="page">
                            <div id="container">
                                <div id="ring"></div>
                                <div id="ring"></div>
                                <div id="ring"></div>
                                <div id="ring"></div>
                                <div id="h3" style={{ color: "black" }}>Uploading...</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Modal isOpen={isCardModalOpen} toggle={toggleCardModalOpen}>
                <ModalHeader toggle={toggleCardModalOpen}><h3>Assign Patient</h3></ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="addFieldValue">Select Vendor :</Label>
                            <Select
                                isClearable
                                options={VendorOptions}
                                classNamePrefix="select"
                                placeholder="Select Template"
                                className={classnames("react-select", {
                                    "is-invalid": errors.ClientName && true,
                                })}
                            />
                        </FormGroup>
                        <br />
                        <Button color="secondary" style={{ float: "right" }} onClick={handleAssignVendor}>Submit</Button>
                    </Form>
                </ModalBody>
            </Modal>
        </Fragment>
    );
}

export default Bulkupload;
