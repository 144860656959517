import React, { useState, useEffect, Fragment, InputHTMLAttributes, forwardRef, useCallback, useMemo } from 'react';
import { Button, Row, Col, Card, CardBody, InputGroup, Input, Nav, UncontrolledTooltip } from 'reactstrap';
import { ChevronLeft, ChevronRight, Download, File, FileText, LogIn, Trash2 } from 'react-feather';
import DataTable from 'react-data-table-component';
import webservice from '../../../Service/webservice';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs';
import 'flatpickr/dist/themes/material_blue.css'; // Import the CSS for the theme you want to use
import { Link, useNavigate, useParams } from 'react-router-dom';
import { saveAs } from 'file-saver'
import { useAppDispatch } from '../../../ReaduxToolkit/Hooks';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { getPatientData, Get_permission } from './store';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';
import websocket_webservice from '../../../Service/websocket_webservice'
import ExcelJS from 'exceljs';

interface DeleteRowData {
    Patientname: string;
    id: string;
    PatientDOB: string;
    Memberid: string;
    CompanyName: string;
    uploaded_date: string;
    RefId: string;
    Payor_ID: string;
    Provider_NPI: string;
    Payer_ID: string;
    Provider_Name: string;
    Provider_ID: string;
    Payer_Name: string;
    Data_Fetched: string;
    xml_conversion: string;
    error_message: string;
    error_reason: string;
    onederfulId: string;
    UserName: string;
	Type: string;
    Payer_name: string;
}


const CustomHeader = ({ value, handleFilter, handlePerPage, rowsPerPage }: any) => {
    return (
        <div className='client-master-list-table-header w-100 py-2'>
            <Row>
                <Col lg='6' className='d-flex align-items-center px-0 px-lg-1'>
                    <div className='d-flex align-items-center me-4 '>
                        <label htmlFor='rows-per-page' className='me-2'>Rows per Page:</label>
                        <Input
                            type='select'
                            id='rows-per-page'
                            value={rowsPerPage}
                            onChange={handlePerPage}
                            className='form-control ms-50 larger-select '
                        >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                        </Input>
                    </div>
                    <Button tag={Link} to={'/BulkUpload'} color='secondary' style={{ marginLeft: "2%" }} >
                        Back
                    </Button>
                </Col>
                <Col
                    lg='6'
                    className='d-flex align-items-center justify-content-lg-end mt-lg-0 mt-1 px-0 px-lg-3'
                >
                    <InputGroup className="input-group-search mb-2 zoom-on-hover">
                        <Input
                            type="text"
                            placeholder="🔍 Search...."
                            value={value}
                            onChange={(e) => handleFilter(e.target.value)}
                            className="input-search"
                        />
                    </InputGroup>
                </Col>
            </Row>
        </div>
    );
};

const Bulkupload: React.FC = () => {


    const navigate = useNavigate();
    const { id } = useParams()
    const [loading, setLoading] = useState(false);
    const action = { fromUser: true };
    const dispatch = useAppDispatch()
    const store = useSelector((state: any) => state.Patients)
    const [value, setValue] = useState<string>('');
    const [sort, setSort] = useState<string>('asc');
    const [sortColumn, setSortColumn] = useState<any>('uploaded_date'); // Example initial sort column
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [eventId, setEventId] = useState<DeleteRowData[]>([]);
    const [patientstatus, setpatientstatus] = useState<string>('');
    const [eventId1, setEventId1] = useState(0);
    const selectedRowsPerPage: { [key: number]: DeleteRowData[] } = useMemo(() => ({}), []);
    const Username = localStorage.UserName;
    const [menuActions, setMenuActions] = useState<string>('');

    useEffect(() => {
        if (localStorage.id !== null) {
            dispatch(Get_permission(localStorage.id)).then((response) => {
                const res1 = response.payload;
                const Action = res1[0].Permission.filter(
                    (item: any) => item.MenuKey === "PATIENTS"
                );
                setMenuActions(Action[0].MenuActions);
            });
        }
    }, [localStorage.id])

    const fetchData = async (temp:any) => {
        if (temp === 'N') {
            setLoading(true);
        }
        if (id !== undefined) {
            try {
                const res = await dispatch(
                    getPatientData({
                        id,
                        q: value,
                        perPage: rowsPerPage,
                        page: currentPage,
                        sort,
                        sortColumn,
                    })
                ).unwrap();

                // setPageData(res); // Update the table data
                setLoading(false);
            } catch (error) {
                console.error('Error fetching patient data:', error);
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        // Fetch initial data with temp === 'N'
        fetchData('N');

        // Establish WebSocket connection
        const socket = new WebSocket(`${websocket_webservice}ws/BulkuploadPatient/`);
        // socket.onopen = () => console.log('WebSocket connected');

        // socket.onmessage = (event) => {
        //     try {
        //         const change = JSON.parse(event.data);
        //         console.log('Real-time update:', change);

        //         // Re-fetch the data with temp === 'Y'
        //         fetchData('Y');
        //     } catch (error) {
        //         console.error('Error parsing WebSocket message:', error);
        //     }
        // };

            socket.onmessage = () => {
                // const change = JSON.parse(event.data);
                // console.log(change);
                // Re-fetch the data to update the state
                fetchData('Y');
            };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        return () => {
            if (socket.readyState === WebSocket.OPEN) {
                socket.close();
            }
        };
    }, [dispatch, id, value, rowsPerPage, currentPage, sort, sortColumn]);


    // useEffect(() => {
    //     const fetchData = () => {
    //         if (id !== undefined) {
    //             dispatch(
    //                 getPatientData({
    //                     id,
    //                     q: value,
    //                     perPage: rowsPerPage,
    //                     page: currentPage,
    //                     sort,
    //                     sortColumn
    //                 })
    //             );
    //         }
    //     };
    //     // Fetch initial data
    //     fetchData();

    //     // Establish WebSocket connection
    //     const socket = new WebSocket(`${websocket_webservice}ws/BulkuploadPatient/`);

    //     socket.onopen = () => console.log('WebSocket connected');
    //     // socket.onclose = () => console.log('WebSocket closed');
    //     // socket.onerror = (error) => console.error('WebSocket error:', error);

    //     socket.onmessage = (event) => {
    //         const change = JSON.parse(event.data);
    //         // console.log(change);
    //         // Re-fetch the data to update the state
    //         fetchData();
    //     };

    //     return () => {
    //         if (socket.readyState === 1) { // <-- This is important
    //             socket.close();
    //         }
    //     }
    // }, [dispatch, store.data.length, id, sort, value, sortColumn, currentPage, rowsPerPage,]);

    const handleReloadTable = () => {
        dispatch(
            getPatientData({
                id,
                q: value,
                perPage: rowsPerPage,
                page: currentPage,
                sort,
                sortColumn
            })
        );
    };


    const handleFilter = (val: any) => {
        setValue(val)
        dispatch(
            getPatientData({
                id,
                q: val,
                perPage: rowsPerPage,
                page: currentPage,
                sort,
                sortColumn,
            })
        )
    }

    const handlePerPage = (e: any) => {
        setCurrentPage(1)
        dispatch(
            getPatientData({
                id,
                q: value,
                perPage: parseInt(e.target.value),
                page: currentPage,
                sort,
                sortColumn,
            })
        )
        setRowsPerPage(parseInt(e.target.value))
    }



    const handlePagination = (page: any) => {
        dispatch(
            getPatientData({
                id,
                q: value,
                perPage: rowsPerPage,
                page: page.selected + 1,
                sort,
                sortColumn,
            })
        )
        setCurrentPage(page.selected + 1)
    }


    const dataToRender = () => {
        const filters: any = {
            q: value

        }
        const isFiltered = Object.keys(filters).some(function (k) {
            return filters[k].length > 0
        })

        if (store.data.length > 0) {
            return store.data
        } else if (store.data.length === 0 && isFiltered) {
            return []
        } else {
            return store.allData.slice(0, rowsPerPage)
        }
    }

    const handleSort = (column: any, sortDirection: any) => {
        setSort(sortDirection)
        setSortColumn(column.sortField)
        dispatch(
            getPatientData({
                id,
                q: value,
                perPage: rowsPerPage,
                page: currentPage,
                sort: sortDirection,
                sortColumn: column.sortField
            })
        )
    }

    const CustomPagination = () => {
        const count = Number(Math.ceil(store.total / rowsPerPage))

        return (
            <ReactPaginate
                previousLabel={<ChevronLeft size={16} />}
                nextLabel={<ChevronRight size={16} />}
                breakLabel="..."
                pageCount={count || 1}
                onPageChange={handlePagination}
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                containerClassName="pagination-container"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="active"
            />
        )
    }


    const [isDownloading, setIsDownloading] = useState(null);

    const handleOpenWordDocument = async (id: any, patient: any) => {
        setIsDownloading(id);
        try {
            const EncParams = AesEncrypt(JSON.stringify({ id: id! })); // Add a non-null assertion here
            const response = await fetch(webservice + 'api/getPatientFile_word', {
                method: 'POST',
                body: JSON.stringify({ EncParams }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            });
            const responseData = await response.json();
            const OutData = AesDecrypt(responseData)

            const docxData = Uint8Array.from(atob(OutData.file), (c) => c.charCodeAt(0));
            const blob = new Blob([docxData], {
                type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            const filename = OutData.filename
            saveAs(blob, filename + '.docx')
            setIsDownloading(null);
            // Check if the file_data key exists
            if (!OutData.file) {
                throw new Error('No file data in response');
            }
        } catch (error) {
            console.error('Error downloading file: ', error);
        }
    };
    const handleregenrateDocument = async (rows: any[]) => {
        setLoading(true);
        try {
            const ids = rows.map(row => row.id);
            const EncParams = AesEncrypt(JSON.stringify({ ids }));
            const fetchResponse = await fetch(webservice + 'api/regenratepatients', {
                method: 'POST',
                body: JSON.stringify({ EncParams }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            });
            const data = await fetchResponse.json();
            const data1 = AesDecrypt(data);

            // await axios.get(webservice + 'api/elligibilityapi');
            // First API call to check the condition
            const api = await axios.get(webservice + 'api/elligibilityapi');
            if (api.data === 'done') {
                const xmlcreation = await axios.get(webservice + 'api/elligibilityapi/Mapping');
                // console.log('XML creation triggered:', xmlcreation.data);
            } else {
                // console.log('API response is not "done". No XML creation triggered.');
            }
            if (data1 === "S") {
                Swal.fire({
                    icon: "success",
                    title: "Insurance Verification Platform",
                    text: "Patients have been Regenrated.",
                    customClass: {
                        confirmButton: "btn btn-success",
                    },
                    allowOutsideClick: false
                });
                setLoading(false);
                navigate(`/patients`);
            }
        } catch (error) {
            console.error('Error downloading file: ', error);
        }
    };
    interface BootstrapCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
        id: string;
    }
    // Handle individual row checkbox change
    const BootstrapCheckbox = forwardRef<HTMLInputElement, BootstrapCheckboxProps>(
        ({ id, ...props }, ref) => (
            <div style={{ marginBottom: '10px', position: 'relative', left: '10px' }}>
                <input type='checkbox' id={id} ref={ref} {...props} />
            </div>
        )
    );

    const [showRegenerateButton, setShowRegenerateButton] = useState(false);
    const timezone = moment.tz.guess();
    const handleChangecheck = useCallback(
        ({ selectedRows }: any) => {
            const selectedEventIds = [];
            selectedRows.forEach((row: any) => {
                selectedEventIds.push(row.id);
                setpatientstatus(row.Data_Fetched)

                const uploadedDate = moment.utc(row.uploaded_date).tz(timezone);

                // Calculate end date by adding Dayscount to the uploaded date
                const endDate = uploadedDate.clone().add(row.Dayscount, 'days');

                // Get current date
                const currentDate = moment.utc(new Date()).tz(timezone);

                if (currentDate.isSameOrAfter(endDate)) {
                    setShowRegenerateButton(true);
                } else {
                    setShowRegenerateButton(false);
                }


            });

            setEventId(selectedRows)
            setEventId1(selectedEventIds.length)


            if (!action.fromUser)
                return;
            selectedRowsPerPage[currentPage] = selectedRows;
        },
        [currentPage, selectedRowsPerPage, action.fromUser]);

    const startViewTimer = () => {
        const startTime = new Date().getTime();
        localStorage.setItem('ViewStartTime', startTime.toString());
    };
    
    
    const Columns = [
        { name: 'Uploaded by', selector: (row: { UserName: string }) => row.UserName ? row.UserName : 'NA', sortable: true },
        { name: 'Patient Name', selector: (row: { Patientname: string }) => row.Patientname ? row.Patientname : 'NA', sortable: true },
        { name: 'Patient DOB', selector: (row: { PatientDOB: string }) => row.PatientDOB ? row.PatientDOB : 'NA', sortable: true },
        { name: 'Member ID', selector: (row: { Memberid: string }) => row.Memberid ? row.Memberid : 'NA', sortable: true },
        { name: 'Client Name', selector: (row: { CompanyName: string }) => row.CompanyName ? row.CompanyName : 'NA', sortable: true },
        { name: 'Payer ID', selector: (row: { Payer_ID: string }) => row.Payer_ID ? row.Payer_ID : 'NA', sortable: true },
        { name: 'Insurance Name', selector: (row: { Payer_name: string }) => row.Payer_name ? row.Payer_name : 'NA', sortable: true, width: "200px" },
        { name: 'Provider ID', selector: (row: { Provider_ID: string }) => row.Provider_ID ? row.Provider_ID : 'NA', sortable: true },
        { name: 'Type', selector: (row: { Type: string }) => row.Type ? row.Type : 'NA', sortable: true },
        { name: 'API Status', selector: (row: { Data_Fetched: string }) => row.Data_Fetched === 'Completed' ? 'Received' : row.Data_Fetched || 'NA', sortable: true },
        {
        name: "View Patient",
            cell: (row: DeleteRowData) => (
            <>
            {row.Data_Fetched === "Completed" && menuActions.includes("edit") && (
                <>
                <FileText
                    size={17}
                    id="editIcon"
                    // color='#FFA500'
                    color={row.xml_conversion === "C" ? '#FFA500' : '#800080'}
                    onClick={() => {
                        startViewTimer();
                        navigate(`/patient-eligibility/${row.id}?view=/bulkupload/patient/${id}`);
                    }}
                    style={{ cursor: 'pointer', marginRight: '10px' }}
                />
                <UncontrolledTooltip placement="top" target="editIcon">
                    View Patient Details
                </UncontrolledTooltip>
                </>
            )}
            {row.xml_conversion === "C" && (
                <div onClick={() => handleOpenWordDocument(row.id, row.Patientname)}>
                    {isDownloading === row.id ? (
                        <div className="spinner"></div>
                    ) : (
                        <Download size={20} style={{ cursor: 'pointer' }} />
                    )}
                </div>
            )}
            {row.Data_Fetched === "Error" && (
                        <span style={{ color: "red" }} title={row.error_message}>
                            {row.error_message.split(" ").slice(0, 10).join(" ")}
                            {row.error_message.split(" ").length > 10 && "..."}
                        </span>
                    )}
        </>
        )
        },
        {
            name: 'Error Reason',
            selector: (row: { error_reason: string }) => row.error_reason || 'NA',
            sortable: true,
            cell: (row: { error_reason: string }) => (
                <span 
                style={{ color: row.error_reason ? 'red' : 'black' }}
                title={row.error_reason}
            >
                {(row.error_reason ? row.error_reason.split(" ").slice(0, 10).join(" ") : "NA")}
                {row.error_reason && row.error_reason.split(" ").length > 10 && "..."}
            </span>
            )
          }
    ]


    const customStyles = {
        rows: {
            style: {
                '&:hover': {
                    backgroundColor: '#f0f0f0',

                },
            },
        },
        headCells: {
            style: {
                backgroundColor: '#355876',
                color: 'white',
                fontSize: '16px',
            },
        },
    };





    const deletePatient = async (ids: any[]) => {
        const EncParams = AesEncrypt(JSON.stringify(ids));
        await fetch(webservice + 'api/bulkupload/deletePatientdetails', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
    };

    const DeleteAuditLogdata = async (data: any[]) => {
        data.forEach(item => {
            item.Userid = localStorage.id;
            item.ParentUserId = localStorage.ParentUserId;
        });
        const EncParams = AesEncrypt(JSON.stringify(data));
        const response = await fetch(webservice + 'api/bulkupload/deleteauditlog', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then(response => response.json());
        return response;
    };

    const DeletePatient = async (rows: any[]) => {
        const ids = rows.map(row => row.id);
        const auditData = rows.map(row => ({
            Username,
            RefId: row.RefId,
            Name: row.Patientname
        }));

        const deleteRow = async () => {
            Swal.fire({
                title: "Insurance Verification Platform",
                text: "Are you sure do you want to delete?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ms-1",
                },
                buttonsStyling: false,
                allowOutsideClick: false
            })
                .then(async function (result) {
                    if (result.isConfirmed) {
                        await deletePatient(ids);
                        const res = await DeleteAuditLogdata(auditData);

                        Swal.fire({
                            icon: "success",
                            title: "Insurance Verification Platform",
                            text: "Clients have been deleted.",
                            customClass: {
                                confirmButton: "btn btn-success",
                            },
                            allowOutsideClick: false
                        });
                        handleReloadTable();
                    }
                });
        };
        deleteRow();
    };


    const startIndex = (currentPage - 1) * rowsPerPage + 1;
    const endIndex = Math.min(currentPage * rowsPerPage, store.total);
    const CustomLoadingComponent = () => {
        return (
            <div className="">
                {store.no_Data === 'There is no Record Found' ? (
                    <h4>
                        <br/>
            <p>There are no records to display</p>
                    </h4>
                ) : (
                    <div className="custom-loader">
                        <label className="custom-label">Please wait...</label>
                        <div className="custom-loading"></div>
                    </div>
                )}
            </div>
        );
    };

    const Exportrow = async (rows: any) => {
        setLoading(true);        
        // Extract only the necessary columns
        const filteredData = rows.map((row: any) => ({
            OnederfulId: row.onederfulId,
            Error_message: row.error_message,
            Error_reason: row.error_reason,
        }));
    
        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Selected Data');
    
        // Add header row with styling
        const headerRow = worksheet.addRow(['OnederfulId', 'Error_message', 'Error_reason']);
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }, // Yellow background
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            };
        });
    
        // Set column widths (adjust these values based on your content)
        worksheet.getColumn(1).width = 40; // OnederfulId
        worksheet.getColumn(2).width = 50; // Error_message
        worksheet.getColumn(3).width = 50; // Error_reason
    
        // Add data rows
        filteredData.forEach((rows : any) => {
            worksheet.addRow([rows.OnederfulId, rows.Error_message, rows.Error_reason]);
        });
    
        // Export to Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Exported_Patient_Data.xlsx';
        link.click();
    
        // Clear selected rows and reset loading state
        selectedRowsPerPage[currentPage] = [];
        handleChangecheck({ selectedRows: [] });
        setLoading(false);
    };
    
    return (
        <Fragment>
            <Breadcrumbs mainTitle='View Patients' parent='Bulk Upload' title='View Patients' to={`/BulkUpload`} />
            <Card>
                <CardBody>
                    <div className="table-responsive">
                        <div className="dataTables_wrapper">
                            <DataTable
                                persistTableHead
                                subHeader={true}
                                responsive={true}
                                onSort={handleSort}
                                data={dataToRender()}
                                columns={Columns}
                                paginationServer
                                customStyles={customStyles}
                                paginationPerPage={rowsPerPage}
                                paginationDefaultPage={currentPage}
                                selectableRows
                                selectableRowsHighlight
                                selectableRowsComponent={BootstrapCheckbox as unknown as React.ReactNode}
                                onSelectedRowsChange={handleChangecheck}
                                paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                                noDataComponent={<CustomLoadingComponent />}
                                paginationComponent={CustomPagination}
                                clearSelectedRows={loading}
                                subHeaderComponent={
                                    <CustomHeader
                                        value={value}
                                        rowsPerPage={rowsPerPage}
                                        handleFilter={handleFilter}
                                        handlePerPage={handlePerPage}
                                    />}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ paddingLeft: '2%' }}>
                                    {store.total && (
                                        <span><strong>{`${startIndex} to ${endIndex} of ${store.total} entries`}</strong></span>
                                    )}
                                </div>
                                <div>
                                    <CustomPagination />
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
                {loading && (
                    <div >
                        <div className="loading-overlay">
                            <div id="page">
                                <div id="container">
                                    <div id="ring"></div>
                                    <div id="ring"></div>
                                    <div id="ring"></div>
                                    <div id="ring"></div>
                                    <div id="h3" style={{ color: "black" }}>loading...</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                
            </Card>
            <div>
                {eventId1 > 0 && menuActions.includes('delete') && (
                    <div className="customizer-links open">
                        <Nav className=" flex-column nav-pills">
                            <a className="nav-link" onClick={() => DeletePatient(eventId)}>
                                <span>Delete</span>
                                <Trash2 className="trash-icon" size={20} />
                            </a>
                        </Nav>
                    </div>
                )}
                {/* {eventId1 > 0 && (
    <div className="customizer1-links open">
        {patientstatus !== 'Error' && showRegenerateButton && (
                <Nav className="flex-column nav-pills">
                    <div>
                        <a className="nav-link" onClick={() => handleregenrateDocument(eventId)}>
                            <span>Regenerate</span>
                            <LogIn size={20} style={{ cursor: 'pointer' }} />
                        </a>
                    </div>
                </Nav>
            )}
    </div>
)} */}
{eventId1 > 0 && (
                <div className="customizer1-links open">
                  <Nav className="flex-column nav-pills">
                    <a className="nav-link" onClick={() => Exportrow(eventId)}>
                      <span>Export</span>
                      <Download className="download-icon" size={20} />
                    </a>
                  </Nav>
                </div>
              )}
            </div>
        </Fragment>
    );
}

export default Bulkupload;
